@import "src/styles/_variables.scss";
@import '~bootstrap/scss/mixins/breakpoints';

.rate-item-card, .quote-item-card {
  color: var(--gray-400);
  box-shadow: $box-shadow-sm;
  transition: box-shadow 0.25s ease-in-out;
  padding: 12px;
  margin-bottom: 10px;

  @include media-breakpoint-up(sm) {
    padding: 20px;
  }

  svg {
    width: 32px;
    height: auto;
  }

  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4;
    color: var(--gray-500);
  }

  .body {
    font-weight: 300;
    line-height: 19px;
  }

  .actions {
    transition: visibility 0.25s, opacity 0.25s ease-in-out;

    @include media-breakpoint-up(lg) {
      visibility: hidden;
      opacity: 0;
    }

    svg {
      width: 18px;
      cursor: pointer;
    }
  }

  @include media-breakpoint-up(lg) {
    &:hover {
      box-shadow: $box-shadow;
      transition: box-shadow 0.25s ease-in-out;

      .actions {
        transition: visibility 0.25s, opacity 0.25s ease-in-out;
        visibility: visible;
        opacity: 1;
      }

      .actions svg {
        color: var(--gray-400);
      }
    }
  }
}

.rate-item-card {
  padding: 20px;

  @include media-breakpoint-up(sm) {
    padding: 40px;
  }

  .coverage-graph-item {
    span {
      color: #666666;
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      vertical-align: middle;

      &.discount-tooltip {
        font-size: 12px;
        font-weight: 400;
      }
    }

    svg {
      display: inline-block;
      margin-right: 10px;
      height: 26px;
      width: 26px;
    }
  }

  .icon svg {
    width: 47px
  }

  .title {
    font-size: 20px;
    color: var(--gray-600);
  }

  &__attribute {
    border-bottom: 1px solid var(--light);
    padding: 12px 0;

    .title {
      color: var(--gray-400);
      font-size: 16px;
    }

    .value {
      width: 100px;
    }

    &:last-of-type {
      border-bottom: 0px;
    }

    svg {
      height: 18px;
      width: 18px;
      margin-right: 10px;
    }
  }

  .price-container {
    &__price {
      color: var(--gray-600);
      font-size: 75px;
      line-height: 1;

      &.quote-price {
        font-size: 100px;
        font-weight: 300;
      }

      sup {
        top: -33%;
      }
    }

    &__dollar {
      font-size: 30px;
      font-weight: 100;
    }

    &__text {
      color: #959D9A;
      font-size: initial;
      font-weight: 300;
      line-height: 1.3;
      margin-bottom: 8px;
    }
  }

  .price-fees {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 2.5rem;
  }
}


.vehicle-rate-item,
.rate-driver {
  padding: 14px;

  @include media-breakpoint-up(sm) {
    padding: 30px;
  }

  &__header {
    margin-bottom: 30px;
  }

  .actions {
    opacity: 1;
    visibility: visible;
    width: 60px;
  }
}


.carrier-card {
  border: none;

  .card-body,
  .card-footer {
    padding: 0;
  }

  .card-footer {
    background-color: transparent;
    border: none;
  }

  .card-body h3.title {
    color: var(--gray-600);
    font-size: 30px;
    line-height: 36px;
  }

  .card-body h5.carrier-product {
    color: var(--gray-400);
    font-size: 18px;
  }

  .carrier-image img {
    height: 65px;
    margin-bottom: 1rem;
  }
}
