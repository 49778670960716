$enable-transitions: true;
$enable-prefers-reduced-motion-media-query: true;
@import "src/styles/_variables.scss";
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/transition';

.custom-radio-container {
  padding: 12px 14px;
  border: 1px solid var(--gray-300);
  cursor: pointer;

  @include media-breakpoint-up(sm) {
    padding: 15px 20px;
  }

  &.selected {
    background-color: #197bbd;
    color: white;
  }

  @include media-breakpoint-up(sm) {
    &-inline {
      margin-right: 1rem;
      flex-grow: 1;
    }
  }

  .custom-control-label {
    font-weight: normal;
    cursor: pointer;
    line-height: 24px;
  }

  .custom-control-label:before {
    @include transition(all .25s ease-in);
    background-color: var(--gray-100);
    border: 1px solid var(--gray-300);
    height: 20px;
    width: 20px;
    top: 2px;
  }

  .custom-radio .custom-control-label:after {
    content: none;
  }

  .custom-control-input:checked + .custom-control-label {
    color: white;

    &::before {
      @include transition(all .25s ease-in);
      border-color: var(--gray-100);
    }
  }

  .custom-radio {
    .custom-control-input:checked + .custom-control-label:before {
      border-width: 5px;
      background-color: var(--secondary);
    }
  }

  .custom-checkbox {
    .custom-control-input:checked + .custom-control-label:after {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='12px' viewBox='0 0 10 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Wizard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Add-Driver---selected-fields' transform='translate(-484.000000, -1118.000000)' fill='%23197BBD'%3E%3Cg id='Content' transform='translate(419.000000, 144.000000)'%3E%3Cg id='Discounts' transform='translate(40.000000, 923.000000)'%3E%3Cg id='Checkbox-/-Default' transform='translate(0.000000, 30.000000)'%3E%3Cg id='Checkbox-/-Field-/-Checked-Inverted' transform='translate(20.000000, 15.000000)'%3E%3Cpolygon id='Path' points='13.8976378 6 8.34645669 11.6969697 6.1023622 9.43434343 5 10.5656566 8.34645669 14 9.4488189 12.8686869 15 7.13131313'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      top: .1rem;
      width: 1.3rem;
      height: 1.3rem;
      left: -1.73rem;
    }
  }
}

